@import url("https://fonts.cdnfonts.com/css/cera-round-pro");

:root {
  --black: #121212;
  --first-color: #3b5995;
  --second-color: #def30e;
  --third-color: #92d050;
  --white: #fff;
  --white-2: rgb(222, 225, 236);
  --white-3: rgb(163, 161, 161);
  --bg: #f0f0f0;
  --first-letter: #3b5995;
  --white-card: #fff;
}

* {
  padding: 0;
  margin: 0;
  font-family: sans-serif;
  font-family: "cera round pro", sans-serif;
  box-sizing: border-box;
}
body::-webkit-scrollbar-track {
  background-color: none;
}

body::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--first-color);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

body {
  background-color: var(--bg);
}

.page-content {
  margin-top: 70px;
  color: var(--black);
}
.date {
  background-color: rgb(42, 165, 65);
  color: var(--white);
  font-weight: bold;
  margin: 4px;
  padding: 6px;
  border-radius: 4px;
  width: max-content;
  font-size: 14px;
}
h1,
h2 {
  margin: 0px;
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 1.25;
}
.black {
  color: var(--black);
}
.white {
  color: var(--white);
}

.play-btn {
  width: 45px;
  height: 45px;
}
.btn {
  appearance: none;
  outline: none;
  border: 0px;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  transition-duration: 0.35s;
  outline: none;
  font-size: 16px;
  padding: 8px;
  font-weight: 500;
  border-radius: 4px;
  background-color: var(--second-color);
  color: var(--black);
}
.btn.border {
  background-color: transparent;
  border: 1px solid white;
  color: var(--white);
}
.btn.nocursor {
  cursor: default;
}
.off {
  margin-left: 6px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
}
.off::before {
  position: absolute;
  content: "";
  height: 2px;
  width: 100%;
  background-color: rgba(255, 6, 6, 0.555);
  transform: rotate(-6deg);
}

.login-form {
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  border-radius: 10px;
  box-shadow: 1px 0 40px rgba(0, 0, 0, 0.04);
  position: relative;
}
.login-form h1,
.login-form h2 {
  font-weight: 600;
  text-align: center;
}
.login-form label {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  margin: 6px 0;
  padding: 0;
}

.login-form label .val-input {
  display: block;
  width: 100%;
  padding: 8px 16px;
  line-height: 25px;
  font-size: 14px;
  font-weight: 500;
  font-family: inherit;
  border-radius: 6px;
  -webkit-appearance: none;
  color: var(--black);
  border: 1px solid var(--black);
  background: var(--white);
  margin: 4px 0;
  transition: border 0.3s ease;
}
.val-input:focus {
  outline: none;
  border-color: var(--first-color);
}

.close {
  cursor: pointer;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 10px;
  top: 10px;
  font-size: 30px;
  background: red;
  color: var(--white);
  border-radius: 20px;
  height: 40px;
  width: 40px;
  border: 0;
}
.dot {
  font-weight: bold;
}
a {
  text-decoration: none;
  color: var(--first-color);
}
.error {
  color: red;
}
.info {
  color: rgb(4, 10, 45);
}
.popup-overlay {
  background: var(--first-color);
}

.popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  padding: 5px;
  border-radius: 10px;
  max-height: 98%;
  max-width: 600px;
  animation: anvil 0.4s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  -webkit-animation: anvil 0.4s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  position: relative;
}
@media screen and (max-width: 500px) {
  .popup-content {
    width: 90%;
  }
}

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}
.bgRed {
  background-color: red;
}
