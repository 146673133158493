.popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  padding: 5px;
  border-radius: 10px;
  max-height: 98%;
  max-width: 600px;
  overflow-y: scroll;
  animation: anvil 0.4s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}
@media screen and (max-width: 500px) {
  .popup-content {
    width: 90%;
  }
}
.popup-arrow {
  color: rgb(255, 255, 255);
}
[role="tooltip"].popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
  background: rgba(9, 1, 58, 0.451);
}
[data-popup="tooltip"].popup-overlay {
  background: transparent;
}

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}

/* Header */
.header {
  position: relative;
  background-color: var(--first-color);
  color: var(--white);
  overflow: hidden;
}
.container {
  position: relative;
  margin: auto;
  z-index: 1;
  padding: 40px 20px;
  width: auto;
  position: relative;
}
@media (min-width: 768px) {
  .container2 {
    width: 900px;
  }
}
section {
  display: flex;
  flex-flow: row wrap;
  -webkit-box-pack: start;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  gap: 30px;
}
@media (min-width: 768px) {
  section {
    flex-wrap: nowrap;
  }
  .row-reverse {
    flex-direction: row-reverse;
  }
}
@media (min-width: 768px) {
}
.container section .media {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container section .description {
}
.container section .description div {
  margin-top: 12px;
}

.container section .description p {
  margin: 12px 0;
  white-space: pre-wrap;
  overflow-wrap: anywhere;
  word-break: normal;
  font-size: 18px;
}
.li {
  padding-left: 20px;
}

/* Vsual */
.visual-image {
  object-fit: cover;
  object-position: center center;
  margin: auto;
  border-radius: 5px;
  height: 500px;
}
@media (max-width: 768px) {
  .visual-image {
    height: 380px;
  }
}
/* Avis */
.vid-grids {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
  width: 100%;
}
@media screen and (max-width: 900px) {
  .vid-grids {
  }
}

.trust {
  width: 100%;
  padding: 12px;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.trust h2 {
  color: var(--first-color);
  font-weight: bold;
}
.trust img {
  width: 150px;
  height: 150px;
}
.trust.success {
  background-color: green;
  color: var(--white);
}
.trust.error {
  background-color: red;
  color: var(--white);
}

.grid {
  overflow: scroll;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  flex: 1;
}
.card {
  position: relative;
  vertical-align: middle;
  align-self: center;
  border-radius: 10px;
  overflow: hidden;
  margin: 10px;
  width: 400px;
  height: 225px;
  display: inline-block;
  box-shadow: var(--shadow-dark);
  background: #fff;
  animation: show 0.4s ease;
}
@keyframes show {
  0% {
    opacity: 0;
    transform: scale(0.4) translateY(20px);
  }

  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}
